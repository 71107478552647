import Vue from 'vue'
import CollapseButton from './CollapseButton.vue'
import CollapseContent from './CollapseContent.vue'
import CollapseDeclaration from './CollapseDeclaration.vue'
import CollapsePanel from './CollapsePanel.vue'
import FormDate from './FormDate.vue'
import FormQuery from './FormQuery.vue'
import FormQueryDeclaration from './FormQueryDeclaration.vue'
import FormSelect from './FormSelect.vue'


const Components = {
  CollapseButton,
  CollapseContent,
  CollapseDeclaration,
  CollapsePanel,
  FormDate,
  FormQuery,
  FormQueryDeclaration,
  FormSelect
}

Object.keys(Components).forEach(name => {
  Vue.component(name, Components[name]);
});

export default Components;
