<template>
    <div class="col-12 col-md-4">
        <div class="input-group mb-2">
            <div class="input-group-prepend">
                <label class="input-group-text" > {{ name }} &nbsp;<i class="fa fa-calendar-o"></i></label>
            </div>
            <input type="date" class="form-control"  v-bind:id="id" v-bind:name="id" :min="min" :max="max" />
        </div>
    </div>
</template>

<script>
export default {
  name: 'FormDate',
  props: {
    name: String,
    id: String,
    min: {
      type: String,
      default: ""
    },
    max: {
      type: String,
      default: ""
    }
  },
}
</script>
