<template>
    <div class="panel panel-default">
        <collapse-button v-bind:active="active" v-bind:btn-title="btnTitle" v-b-toggle="'collapse-' + href"></collapse-button>
        <b-collapse v-bind:id="'collapse-' + href" v-bind:visible="active ? true : false">
            <slot></slot>
        </b-collapse>
    </div>
</template>

<script>
import CollapseButton from './CollapseButton.vue'

export default {
  components: {
    CollapseButton,
  },
  name: 'CollapsePanel',
  props: {
    btnTitle: String,
    href: String,
    active: Boolean
  },
}
/*
<collapse-content v-bind:id="'accordion-' + href" v-bind:active="active">
            <slot></slot>
        </collapse-content>
*/
</script>
