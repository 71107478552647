<template>
    <div class="panel-heading" v-bind:class="{ active: isActive }" role="tab">
        <h4 class="nav-content-header">
            <a v-on:click="toggle()" class="accordionHeader" role="button" data-toggle="collapse" href="#">
                {{ btnTitle }}
            </a>
        </h4>
    </div>
</template>

<script>
export default {
  name: 'CollapseButton',
  props: {
    btnTitle: String,
    active: Boolean
    },
  data: function () {
    return {
      isActive: this.active ? this.active : false,
    }
  },
  methods: {
    toggle() {
      this.isActive == false ? this.isActive = true : this.isActive = false;
    }
  }
}
</script>

<style scoped>
.panel-heading  a:before {
    font-family: 'FontAwesome';
    content: '\f107';
    float: left;
    transition: all 0.5s;
    color: #000000;
    font-weight: 400;
        outline: 0;
    text-decoration: none;
}
    .active a:before {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        transform: rotate(180deg);
}

.nav-content-header {
    font-size: 26px;
    font-weight: 800;
    color: #33ACCB;
}

.accordionHeader {
    outline: 0;
    text-decoration: none;
    margin-left: 10px;
    color: #33accb
}

h4 {
    line-height: 1.2;
    font-weight: 300;
    margin-bottom: 1.5rem;
    font-family: 'Raleway';
     outline: 0;
    text-decoration: none
}
</style>
