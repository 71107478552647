<template>
    <collapse-panel v-bind:btn-title="btnTitle" v-bind:href="href" v-bind:active="active">
        <collapse-content v-bind:description="description">
            <form-query-declaration :min="min ":max="max">
                <slot></slot>
            </form-query-declaration>
        </collapse-content>
    </collapse-panel>
</template>

<script>
import CollapsePanel from './CollapsePanel.vue'
import CollapseContent from './CollapseContent.vue'
import FormQueryDeclaration from './FormQueryDeclaration.vue'

export default {
  components: {
    CollapsePanel,
    CollapseContent,
    FormQueryDeclaration
  },
  name: 'CollapseDeclaration',
  props: {
    btnTitle: String,
    href: String,
    description: String,
    active: Boolean,
    min: {
        type:String,
        default: '2021-01-01'
    },
    max: {
        type:String,
        default: '2023-12-31'
    },
  },
}
</script>
