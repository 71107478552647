<template>
    <form-query action="api/poseimaDecl" submitBtn="Exportar PDF" icon="fa-file-pdf-o text-danger">

         <form-select name="Mapa:" id="map_select">
            <option value="" selected>Selecionar...</option>
            <slot></slot>
        </form-select>
        <form-date name="Início" id="start_date" :min="min" :max="max"></form-date>
        <form-date name="Fim" id="final_date" :min="min" :max="max"></form-date>

        <div class="col-12 col-lg-10"></div>
    </form-query>
</template>

<script>
import FormSelect from './FormSelect.vue'
import FormDate from './FormDate.vue'
import FormQuery from './FormQuery.vue'

export default {
  components: {
      FormSelect,
      FormDate,
      FormQuery
  },
  name: 'FormQueryDeclaration',
  props: {
    action: String,
    min: {
        type:String,
        default: '2021-01-01'
    },
    max: {
        type:String,
        default: '2023-12-31'
    },
  },
}
</script>

<style>
/* .btn-export {
    color: #FFF;
    background-color: #F68A2B;
    border-radius: 10px;
}

.btn-export:hover {
    background-color: #f68a2b;
} */
</style>
