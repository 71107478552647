<template>
    <div class="col-12 col-md-4">
        <div class="input-group mb-2">
            <div class="input-group-prepend">
                <label class="input-group-text" > {{ name }} &nbsp;<i class='fa' v-bind:class="icon"></i></label>
            </div>
            <select class="form-control" v-bind:id="id" v-bind:name="id">
                <slot></slot>
            </select>
        </div>
    </div>
</template>

<script>
export default {
  name: 'FormSelect',
  props: {
    name: String,
    id: String,
    icon: String
  },
}
</script>
