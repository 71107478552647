<template>
    <div class="card-body form-margin">
        <div v-if="description" class="alert alert-warning" id="alert_info" role="alert">
            <i class="fa fa-warning"></i>
            <b> {{ description }} </b>
        </div>
        <slot>
        </slot>
    </div>
</template>

<script>
export default {
  name: 'CollapseContent',
  props: {
    description: String
  },
}
</script>
