<template>
    <form v-bind:action="action" class="ajax-form-post">
        <div class="row">
           <slot></slot>
           
            <div class="col-12 col-lg-2">
                <img id="ajaxSpinnerDec" src="/img/loader.gif" class="li-loader-ani" alt="Wait" style="opacity: 0.5; display: none;">
                <button :onclick="onbtnclick" :id="btnId" type="submit" class="btn btn-block btn-export">{{ submitBtn }} <i class='fa' v-bind:class="icon" style="vertical-align: middle; color:#FFD500"></i></button>
            </div>
            
        </div>
    </form>
</template>

<script>
export default {
  name: 'FormQuery',
  props: {
    action: String,
    submitBtn: String,
    icon: String,
    onbtnclick: String,
    btnId: String
  },
}

</script>

<style>
/* .btn-export {
    color: #FFF;
    background-color: #F68A2B;
    border-radius: 10px;
}

.btn-export:hover {
    background-color: #f68a2b;
} */
</style>
